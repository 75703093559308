export const excludedWordList = `
abraham
african
alabama
albania
alberta
algeria
america
ampland
anaheim
andorra
andreas
andrews
angeles
anthony
antigua
antonio
arizona
armenia
atlanta
austria
baghdad
bahamas
bahrain
barbara
beatles
bedford
beijing
belarus
belfast
belgium
bennett
bermuda
bernard
beverly
bizrate
boolean
bradley
brandon
britain
british
britney
calgary
cameron
cardiff
carroll
charles
chelsea
chester
chicago
chinese
clinton
coleman
cornell
croatia
deborah
denmark
detroit
deutsch
douglas
ecuador
edwards
elliott
england
estonia
expedia
ferrari
findlaw
finland
finnish
firefox
florida
francis
freebsd
fujitsu
gabriel
georgia
germany
glasgow
gratuit
gregory
grenada
halifax
hampton
harvard
hewlett
hitachi
hopkins
hotmail
houston
hungary
hyundai
iceland
indiana
indians
ireland
islamic
israeli
italian
jackson
jamaica
january
jeffrey
jelsoft
jessica
katrina
kennedy
kenneth
lebanon
leonard
levitra
lexmark
liberia
lincoln
lindsay
livecam
madison
marilyn
matthew
medline
melissa
memphis
mexican
michael
minolta
moldova
montana
mozilla
myanmar
mysimon
myspace
namibia
newport
niagara
nigeria
norfolk
oakland
olympic
olympus
ontario
orlando
orleans
packard
patrick
pentium
persian
philips
plugins
podcast
pokemon
pontiac
porsche
preston
raleigh
raymond
rebecca
reuters
richard
roberts
romania
russell
russian
samsung
seattle
senegal
shakira
shannon
sherman
simpson
solaris
solomon
somalia
spanish
stanley
stephen
stevens
stewart
swedish
thomson
toronto
toshiba
tuesday
tunisia
turkish
ukraine
uruguay
vatican
verizon
vermont
vietnam
vincent
wallace
wichita
william
windsor
winston
wyoming
zealand
adidas
adipex
adrian
albert
alfred
amanda
ambien
andale
andrea
andrew
angola
approx
arabia
arnold
arthur
ashley
athens
barnes
belize
belkin
bhutan
bosnia
bryant
calvin
carlos
celtic
cialis
clarke
compaq
curtis
dakota
dallas
daniel
darwin
dayton
dennis
denver
disney
donald
dublin
duncan
durham
edward
eminem
eugene
flickr
forbes
france
fraser
garcia
garmin
george
gordon
grande
greece
greene
guyana
hansen
harley
harold
harris
hawaii
helena
hilton
holdem
holmes
howard
hudson
hughes
italia
jackie
jeremy
joshua
julian
justin
kansas
kelkoo
kijiji
kruger
kuwait
latvia
lauren
leslie
london
louise
luther
madrid
mailto
malawi
marcus
mariah
marion
martha
mexico
michel
milton
monaco
monday
monica
monroe
moscow
msgstr
mumbai
munich
murray
naples
nascar
nasdaq
nathan
nevada
newark
nextel
nicole
nissan
norman
norton
norway
nvidia
oregon
ottawa
pamela
paypal
philip
pierre
poland
powell
prague
prozac
pubmed
quebec
rachel
robbie
robert
ronald
russia
rwanda
saddam
samuel
saturn
serbia
sharon
signup
slovak
steven
struct
stuart
subaru
sussex
suzuki
sweden
sydney
taiwan
taylor
thehun
thomas
titten
travis
trembl
tucson
uganda
valium
venice
vernon
vienna
voyuer
wagner
watson
wesley
wilson
womens
yamaha
zambia
zoloft
zshops
aaron
adams
albany
ultram
murphy
roland
claire
gerald
austin
harvey
walter
toyota
apollo
sandra
angela
newman
tobago
karen
lexus
acer
adam
adsl
alex
alot
andy
anne
arab
asin
asus
audi
avon
bali
benz
biol
blvd
bool
cdna
chan
chem
chen
cnet
comm
conf
corp
cruz
ctrl
cuba
dana
dept
dist
divx
dont
doug
dvds
ebay
eden
emma
eric
erik
espn
eval
faqs
feof
fiji
foto
fred
gary
gmbh
goto
gras
greg
guam
gzip
hans
hdtv
href
html
http
hugh
hugo
ieee
incl
intl
iowa
ipaq
ipod
iran
iraq
isbn
issn
jeff
joan
joel
jose
jpeg
juan
judy
karl
kate
kong
kurt
kyle
laos
leon
lisa
lucy
luis
luke
lynn
mali
mary
maui
ment
mins
misc
mpeg
mrna
msie
muze
nasa
nato
ncaa
neil
nike
ntsc
oclc
oecd
ohio
oman
owen
paso
paul
pdas
penn
perl
peru
pete
phil
phys
pmid
prev
prix
proc
prot
reid
reno
rica
rico
rome
rosa
ross
ryan
sara
scsi
sean
sega
smtp
sony
suse
thai
tion
todd
treo
univ
unix
urls
usda
usgs
usps
utah
viii
voip
walt
xbox
yale
york
zope
israel
bufing
itunes
nutten
puerto
brunei
oliver
berlin
carter
debian
epson
louis
kodak
nokia
casio
jesse
howto
alice
allan
allen
annie
apnic
aruba
barry
betty
blair
blake
brian
bruce
bryan
carey
carlo
casey
chris
cindy
clara
clark
cohen
const
craig
czech
danny
davis
derek
diana
diane
dicke
diego
dover
dubai
dylan
eddie
edgar
egypt
ellen
ellis
elvis
emacs
emily
endif
essex
evans
filme
floyd
fotos
ghana
glenn
haiti
hayes
helen
idaho
india
iraqi
isaac
italy
jacob
james
jamie
janet
jason
jesus
joyce
julia
julie
kathy
katie
keith
kenny
kenya
kevin
kinda
klein
korea
lanka
larry
leeds
linda
lloyd
lopez
lucas
lucia
lycos
maine
malta
marco
mardi
marie
mario
mazda
meyer
miami
milan
moore
moses
mpegs
msgid
multi
myers
mysql
nepal
niger
nikon
notre
omaha
papua
paxil
perth
phpbb
qatar
rhode
ricky
sagem
salem
samoa
sanyo
sarah
saudi
sbjct
scott
simon
singh
skype
spain
sparc
steve
sudan
susan
syria
tahoe
tamil
tampa
tions
tokyo
tracy
tulsa
twiki
tyler
utils
vegas
verde
volvo
vsnet
wayne
wendy
wiley
xanax
xhtml
yemen
yukon
zdnet
`.trim().split('\n');